<i18n>
	{
		"en": {
			"settings": "Settings",
			"basicSetting": "Basic Settings",
			"playSound": "Play sounds on transactions?",
			"logoutWhenInactive": "Logout when inactive?",
			"forgotTransactionPin": "Forgot Transaction PIN?",
			"getOTP": "Get OTP Code.",
			"connectedDevices": "Connected Devices",
			"reloadInfo": "Reload now for changes to take effect immediately",
			"reload": "Reload"
		},
		"np": {
			"settings": "सेटिङहरू",
			"basicSetting": "आधारभूत सेटिङ",
			"playSound": "लेनदेनमा ध्वनि बजाउने?",
			"logoutWhenInactive": "निष्क्रिय हुँदा लगआउट?",
			"forgotTransactionPin": "लेनदेन पिन बिर्सनुभयो?",
			"getOTP": "OTP कोड प्राप्त गर्नुहोस्।",
			"connectedDevices": "जडित यन्त्रहरू",
			"reloadInfo": "परिवर्तन तुरुन्तै प्रभावकारी हुनको लागि अहिले पुन: लोड गर्नुहोस्",
			"reload": "पुनः लोड गर्नुहोस्"
			}
	}
</i18n>

<template lang="pug">
		.container
				.card
						.card-body
								.col-12.col-md-6
										h4.mb-3 {{ $t("settings") }}
										.h5.font-weight-bold {{ $t("basicSetting") }}
										.row
												.col {{ $t("playSound") }}
												b-checkbox(switch v-model='sound' @input='setSound'  )
										.row.mt-1
												.col {{ $t("logoutWhenInactive") }}
												b-checkbox(switch v-model='logoutWhenInactive' @input='setLogout')
										//.row
											.col Ask for transaction PIN?
											b-checkbox(switch v-model='askForPin' @input='requestPin')
										.row.mt-1
											.col {{ $t("forgotTransactionPin") }}
											router-link(to="reset-pin") {{ $t("getOTP") }}
										hr
										.row.mb-5
											.col.mb-2 {{ $t("connectedDevices") }}
												.row.my-3(v-for="connected in connectedDevices" :key="connected._id")
													.col
														.row
															.col-8
																span {{connected.device.device.brand}} {{connected.device.client.type === "browser" ? connected.device.client.name: connected.device.device.model}} ( {{connected.ip}} )
															.col
																button.btn.btn-light.btn-sm(@click="remove(connected.uuid)") Remove
										.row.mt-1
											.col
												small.text.text-muted.mt-3 {{$t("reloadInfo")}}
										.row
											.col
												button.mt-2.btn.btn-primary.btn-sm(@click='reload') {{$t("reload")}}
										
</template>

<script>
import { REMOTE_SERVER_1 } from "../../helpers/general";
import axios from "@/axios";
export default {
	data() {
		return {
			sound: localStorage.getItem("playAudio") || false,
			logoutWhenInactive: localStorage.getItem("logoutWhenInactive") || true,
			askForPin: localStorage.getItem("askForPin") || true,
			connectedDevices: [],
		};
	},
	methods: {
		setSound() {
			localStorage.setItem("playAudio", this.sound);
		},
		setLogout() {
			localStorage.setItem("logoutWhenInactive", this.logoutWhenInactive);
		},
		requestPin() {
			localStorage.setItem("askForPin", this.askForPin);
		},
		reload() {
			location.reload();
		},
		async remove(id) {
			console.log(id);
			await axios.post(REMOTE_SERVER_1 + "user/signin/invalidate/session", {
				uuid: id,
			});
			this.connectedDevices = this.connectedDevices.filter((c) => c.uuid !== id);
			if (id === localStorage.getItem("_UUID")) {
				location.reload();
			}
		},
	},
	async mounted() {
		const sessions = await axios.get(REMOTE_SERVER_1 + "user/signin/current/sessions");
		this.connectedDevices = sessions.data;
	},
};
</script>
